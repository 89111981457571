import { apiMain } from "../../api/clients";
import { ApiControllers } from "../../api/config";
const getportfolio = (member_id) => {
	return apiMain.get(ApiControllers.wallets + `Crypto/${member_id}`);
};
const getCryptos = () => {
	return apiMain.get(ApiControllers.buySell + "Coins");
};
const getMemberfiat = (member_id) => {
	return apiMain.get(ApiControllers.wallets + "Fiat/" + member_id);
};
const getSellamnt = (
	Value,
	isSwap,
	coin,
	isCrypto,
	memId,
	screenName,
	currencyCode
) => {
	return apiMain.get(
		ApiControllers.master +
		`CryptoFiatConverter/${memId}/${coin}/${currencyCode}/${Value}/${isCrypto}/${screenName || null
		}`
	);
};
const getSellPreviewData = (sellObject) => {
	return apiMain.get(
		ApiControllers.buySell +
		`Sell/${sellObject?.membershipId}/Coins/${sellObject?.fromWalletCode}/${sellObject?.toWalletCode
		}/${sellObject.isSwap ? sellObject.fromValue : sellObject.toValue}/${sellObject.isSwap === true ? false : true
		}`
	);
};
const savesellData = (obj) => {
	return apiMain.post(ApiControllers.buySell + "Sell", obj);
};
const getCoins = (type) => {
	return apiMain.get(ApiControllers.markets + `Coins/${type}`);
};
const getSelectedCoinDetails = (coin_code, member_id) => {
	return apiMain.get(
		ApiControllers.buySell + `${member_id}/Coins/${coin_code}`
	);
};
const fetchCurrencyConvertionValue = ({
	from,
	to,
	value,
	isCrypto,
	memId,
	screenName,
}) => {
	return apiMain.get(
		ApiControllers.master +
		`CryptoFiatConverter/${memId}/${from}/${to}/${value}/${isCrypto}/${screenName || null
		}`
	);
};
const getPreview = ({ coin, currency = "USD", amount, isCrypto, customerId }) => {
	return apiMain.get(
		ApiControllers.buySell +
		`Buy/${customerId}/Coins/${coin}/${currency}/${amount}/${isCrypto}`
	);
};
const buyCrypto = (obj) => {
	return apiMain.post(ApiControllers.buySell + `Buy`, obj);
};
export {
	getportfolio,
	getCryptos,
	getMemberfiat,
	getSellamnt,
	getCoins,
	getSelectedCoinDetails,
	fetchCurrencyConvertionValue,
	getSellPreviewData,
	savesellData,
	getPreview,
	buyCrypto,
};
