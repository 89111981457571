import { Component, useEffect, useState } from "react";
import { Typography, Menu, Layout, Dropdown } from "antd";
import { Link } from "react-router-dom";
import logoColor from "../assets/images/logo-color.png";
import logoWhite from "../assets/images/logo-white.png";
import DefaultUser from "../assets/images/defaultuser.jpg";
import { userManager } from "../auth";
import { connect } from "react-redux";
import counterpart from "counterpart";
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { store } from '.././store';
import Translate from "react-translate-component";
import en from "../lang/en";
import ch from "../lang/ch";
import my from "../lang/my";
counterpart.registerTranslations("en", en);
counterpart.registerTranslations("ch", ch);
counterpart.registerTranslations("my", my);

const { Text } = Typography;

function AppHeader(props) {
	const [Visibleprofilemenu, setVisibleprofilemenu] = useState("");
	const { switcher, themes } = useThemeSwitcher()
	useEffect(() => {
		const { userConfig: { userProfileInfo } } = store.getState();
		switcher({ theme: userProfileInfo?.theme == 'Light Theme' ? themes.LHT : themes.DRT });
		counterpart.setLocale(props.userConfig ? props.userConfig.language || "en" : "en");
	}, [])

	const getAccountType = () => {
		if (props.userConfig.role === "Admin") {
			return "Admin";
		}
		else if (props.userConfig.role !== "Admin" && props.userConfig.isBusiness) {
			return "Business";
		}
		else {
			return "Personal";
		}
	}
	const clearEvents = () => {
		window.$zoho?.salesiq?.chat.complete();
		window.$zoho?.salesiq?.reset();
		userManager.signoutRedirect();
	}

	const userProfileMenu = (
		<Menu>
			<div className="profile-dropdown pb-0">
				{props.userConfig?.imageURL != null && (
					<img
						src={
							props.userConfig?.imageURL
								? props.userConfig?.imageURL
								: DefaultUser
						}
						className="user-profile"
						alt={"image"}
					/>
				)}
				{props.userConfig?.imageURL === null && (
					<img
						src={
							props.userConfig?.imageURL
								? props.userConfig?.imageURL
								: DefaultUser
						}
						className="user-profile"
						alt={"image"}
					/>
				)}
				<p className=" ml-8 profile-value" style={{ flexGrow: 12 }}>
					{props.userConfig.firstName ? props.userConfig.firstName : '--'} {props.userConfig.lastName ? props.userConfig.lastName : '--'}
				</p>
				<ul className="pl-0 drpdwn-list">
					<li
						onClick={() => window.open('https://suissebase.egnyte.com/ul/jnDqGI4Nxj', '_blank')}
					>
					</li>
					<li>
						<Link onClick={() => clearEvents()}>
							<Translate
								content="logout"
								className="text-white-30"
								component={Text}
							/>
						</Link>
					</li>
				</ul>
			</div>
		</Menu>
	);

	return (
		<>
			<Layout className="layout">
				<div className="tlv-header" id="area">
					<div className="login-user">
						<ul className="header-logo pl-0">
							<li className="pr-30 p-relative">
								{
									<img
										src={logoWhite}
										alt="logo"
										className="tlv-logo dark "
									/>
								}
								{
									<img
										src={logoColor}
										alt="logo"
										className="tlv-logo light "
									/>
								}
							</li>
							<li className="mb-d-none px-20">
								{props.userConfig?.role === "Customer" ?
									<>   <Translate
										content="header_title"
										//onClick={this.routeToCockpit}
										component={Text}
										className="text-white-30 fs-16 c-pointer cp-link ml-8"
									/>
										<Text className="text-white-30 fs-24">|</Text>
										<Translate
											content="user_type"
											with={{
												lable: props.userConfig?.isBusiness
													? "Business"
													: "Personal"
											}}
											component={Text}
											className="text-white-30 fs-16 ml-16"
										/></> : <>
										<Text className="text-white-30 fs-24 ml-16">{getAccountType()} Profile</Text>
									</>}
							</li>
						</ul>
					</div>
					<Menu
						theme="light"
						mode="horizontal"
						className="header-right mobile-headerview">
						<Menu.Item key="1" className="text-plain">
							{props.userConfig.email}
						</Menu.Item>
						<Dropdown
							const onVisibleChange={() =>
								setVisibleprofilemenu(!Visibleprofilemenu)
							}
							visible={Visibleprofilemenu}
							onClick={() =>
								setVisibleprofilemenu(true)
							}
							overlay={userProfileMenu}
							placement="topRight"
							arrow
							overlayClassName="secureDropdown"
							getPopupContainer={() => document.getElementById("area")}
						>
							<Menu.Item key="8" className="ml-16">
								{props.userConfig?.imageURL != null && (
									<img
										src={
											props.userConfig?.imageURL
												? props.userConfig?.imageURL
												: DefaultUser
										}
										className="user-profile"
										alt={"image"}
									/>
								)}
								{props.userConfig?.imageURL === null && (
									<img
										src={
											props.userConfig?.imageURL
												? props.userConfig?.imageURL
												: DefaultUser
										}
										className="user-profile"
										alt={"image"}
									/>
								)}
							</Menu.Item>
						</Dropdown>
					</Menu>
				</div>
			</Layout>
		</>
	);
	//}
}

const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps)(AppHeader);
