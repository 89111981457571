export default {
  header_title: '座艙',
  user_type: '%(lable)s',
  Portfolio_title: '加密投资组合',
  your_portfolio: '您的投資組合',
  suissebase_title: '瑞士基地',
  suissebase_subtitle: '菲亚特秤',
  markets_title: '市场',
  markets_subtitle: '将您的加密货币交易成任何货币',
  deposit: '订金',
  withdraw: '提取',
  buy: '買',
  sell: '賣',
  load_more: '加载更多 ...',
  ftr_home: '家',
  ftr_careers: '职业',
  ftr_tc_p: '勒根与政策',
  search_currency: '搜索货币',
  buy_assets: '购买资产',
  // past_hours: '在过去的 24 小时内',
  deposite_a_crypto: '存入加密货币',
  deposite_a_cryto_txt: '只需分享您的 QR 地址即可获得加密货币',
  purchase_a_crypto: '以現金購買您的加密貨幣',
  // purchase_a_cryto_txt: '你的钱包是空的，你没有任何资产可以进行交易。点击此链接并存入一些现金。',
  all: '全部',
  tabs_All: '全部',
  gainers: '赢家',
  losers: '失败者',
  db_slider_title: '获得 $10 个免费加密货币',
  db_slider_desc: '设置重复购买 $50 次或更多，并在第 $4 次和第 8 次购买后获得 10 次',
  db_slider_btn: '设置定期购买',
  selected_crypto: '買 eth',
  crypto_compare_val: '1 ETH - $2,495.31 ',
  find_with_wallet: '選擇你的錢包',
  refresh_newprice: '请刷新以获取新价格',
  confirm_btn_text: '确认 %(counter)s',
  //confirm_btn_text: '确认',
  exchange_rate: '汇率',
  amount: '数量',
  suissebase_fee: '瑞士基地 费用',
  estimated_total: '估计总数',
  summary_hint_text: '您的最终金额可能会在 10 秒内更改.',
  pay: '支付 %(counter)s',
  cancel: '取消',
  link_a_card: '链接卡片或接收',
  select_from_below: '从下面选择',
  credit_card: '信用卡',
  credit_card_text: '使用信用卡或借记卡',
  desosit_text: '从地址或现有钱包存款',
  name_on_card: '卡片上的名字',
  card_number: '卡号',
  expiry: '到期',
  cvv: '变异系数',
  type_billing_address: '输入您的帐单地址',
  change_billing_address: '更改帐单地址',
  confirm_billing_address: '确认账单地址',
  billing_address: '帐单地址',
  delivery_address: '您的收货地址',
  select_a_currency: '选择货币',
  address: '地址',
  address_hint_text: '请确保您的收货地址正确无误',
  copy: '复制',
  share: '分享',
  from_crypto: '来自加密',
  from_fiat: '来自菲亚特',
  wire_transfer_mthd: '电汇方式',
  recommended: '受到推崇的',
  select_a_method: '选择方法',
  sepa: '分离',
  wire_transfer: '电汇',
  swift: '迅速',
  no_fee: '不收费',
  sell_btc: "不收费C",
  select_wallet1: "從你的錢包裡賣",
  confirm_sell: "确认出售您的加密货币",
  sell_compare_val: '1 BTC - $34,818.98',
  preview: '预习 %(counter)s',
  success_msg: "謝謝你",
  success_decr: "您的訂單已成功下單",
  confirm_msg: '等待确认中',
  confirm_decr: "您的订单已下 确认",
  withdraw_a_crypto: "提取加密货币",
  withdraw_a_crypto_text: '为您的加密钱包提款和存款。',
  usd_available: "USD 106.79 可用的",
  send_wallet_fiat: '从你的钱包发送到法定货币',
  suissebase_personal: '瑞士基地 个人的',
  avail_wallet_weprovide: '我们提供的可用钱包',
  withdraw_Btc: '提現比特幣',
  withdraw_summary_sub: '发送您的货币地址。',
  verify_identity: '验证您的身份',
  swap_title: '新掉期',
  swap_desc: '立即將您的加密貨幣兌換成我們在您錢包中提供的任何貨幣',
  swap_from: '交換自',
  swap_to: '接受',
  wallet_in_usd: '钱包在 USD',
  purchase_fiat: '购买菲亚特',
  purchase_fiat_text: '你的钱包是空的，你没有任何资产可以进行交易。点击此链接并存入一些现金.',
  link_newcard: '链接新卡',
  pick: '挑選',
  confirm_swap: '確認交換',
  total: '全部的',
  confirm_transaction: '確認交易',
  confirm_transaction_decs: '美元錢包',
  add_fund: '增加资金',
  confirm_text: '交换 0.00549635 BTC 为了 0.10183318 ETH',
  deposite_Btc: '从你的钱包里卖',
  sell_your_crypto_for_cash: '出售您的加密货币以换取现金',
  sell_your_crypto_for_cash_text: '直接从您的钱包轻松买卖加密货币。',
  sell_your_fiat_for_cash: '出售您的法定货币以换取现金',
  menu_buy_sell: '买卖',
  menu_transactions_history: '交易',
  menu_swap: '交换',
  menu_send_receive: '存款/取款',
  menu_mass_pay: '批量付款',
  menu_payments: '付款',
  security: '安全',
  current_balance: '当前余额',
  min: '最小',
  half: '一半',
  assets: '资产',
  confirm_now: '立即确认 %(counter)s',
  buy_sell_address: 'UNIT 527 TOWER 4, SMDC Grace Residences, Cayetano Blvd.布吉。 Ususan, 达义市 1630 PH',
  withdraw_to: '撤回',
  btc_address: 'BTC币安地址',
  fund_with_card: '有卡基金',
  fund_with_card_text: '你想加多少？',
  preview_swap: '预览交换',
  deposit_link: '你的钱包是空的，你没有任何资产可以进行交易。按照这个链接和 %(link)s 一些现金。',
  link: 'link',
  agree_to_suissebase: '我同意 Suissebase 的 %(link)s 及其退货、退款和取消政策。',
  terms_service: '服务条款',
  return_to_buy: '返回购买',
  return_to_sell: '返回出售',
  fidor_bank: 'Fidor 银行',
  transaction_submitted: '交易提交',
  swapped_btc: '将 0.00549635 BTC 换成 0.10183318 ETH',
  bank_frick: '银行弗里克',
  scan_your_crypto: '扫描您的 BTC',
  center_qr: '将 QR 扫描仪居中',
  close: '关闭',
  card_supported: '不支持卡类型',
  need_to_replenish: '需要补充你的钱包吗？按照此链接和 %(link)s 一些现金。',
  lets_verified: "让我们帮你验证",
  confirm_your_identity: 'Suissebase.ch 需要确认您的身份，该流程由我们的合作伙伴提供支持',
  prepare_valid: '准备有效文件',
  make_sure: "确保它没有因物理损坏而过期",
  user_smartphone: '使用智能手机',
  you_need_smartphone: '您需要一部智能手机才能继续',
  qption1: '方式一：扫描二维码',
  qption1_answer: '使用您的相机应用程序扫描二维码',
  qption2: '选项 2：通过短信发送链接',
  qption2_answer: '我们将向您的手机发送安全链接，无需额外费用。',
  return_to_fiat: '重返菲亚特',
  admin_fee: '管理费',
  free: '自由',
  to: '至',
  from: '从',
  current_security_level: '当前的安全级别',
  current_security_text: '您的帐户已关闭安全功能，使其可能容易受到特定攻击。设置这些安全功能以提高您帐户的安全性。',
  medium: '中等的',
  change_pass_word: '更改密码',
  protect_your_account: '保护您的帐户',
  settings: '设置',
  general: '一般的',
  privacy_policy: '隐私政策',
  logout: '登出',
  themes: '主题',
  notifications: '通知',
  local_currency: '当地货币',
  language: '语',
  preferences: '首选项',
  wallet_version: '钱包版',
  about: '关于',
  USD_wallet: '美元钱包',
  start: '开始',
  start_text: '我们是一个连接银行、支付系统和人员的平台',
  personal: '个人的',
  wallets: '钱包',
  wallets_text: '完全控制您的私钥。',
  cards: '牌',
  exchange: '交换',
  business: '商业',
  business_text: '用户可以创建一个单独的帐户，例如企业隔离钱包系统。',
  crypto: '加密',
  corporate_wallet: '企业钱包',
  connect: '连接',
  meet_our_team: '认识我们的团队',
  report_a_bug: '报告错误',
  FAQ: '常问问题',
  contact_us: '联系我们',
  sign_in: '登入',
  security_text: '您的帐户已关闭安全功能，使其可能容易受到特定攻击。设置这些安全功能以提高您帐户的安全性。',
  spend: '花费',
  spend_text: '您的帐户已关闭安全功能，使其可能容易受到特定攻击。设置这些安全功能以提高您帐户的安全性。',
  chat: '聊天',
  current_security: '当前的安全级别',
  status: '查看您的状态',
  michael_quiapos: '迈克尔·奎波斯',
  great: '伟大的。我会看看的...',
  address_book: '地址簿',
  invite_friends: '邀请朋友',
  light_theme: '轻主题',
  localization: '本土化',
  localization_text: '用户可以创建一个单独的帐户，例如企业隔离钱包系统。',
  lang: '朗',
  currency: '货币',
  support: '支持',
  help_center: '帮助中心',
  social_networks: '社交网络',
  medium_text: '当前安全级别 %(link)s 查看您的状态',
  backup_wallet: '备用钱包',
  reset_wallet: '重置钱包',
  always_ask_pin: '总是问针',
  activate_face: '激活人脸识别',
  activate_biometry: '激活生物识别',
  buy_and_sell: '买和卖',
  swap_services: '掉期服务',
  deposit_and_withdraw: '存款和取款',
  the_dashboard: '仪表板',
  buy_crypto: '购买加密货币',
  Beneficiary_BankDetails: '收款银行详情',
  // Bank_account: '银行账号/IBAN',
  Bank_account: '銀行賬戶',
  BIC_SWIFT_routing_number: 'BIC/迅捷/路由号码',
  Bank_name: '银行的名字',
  Bank_address1: '银行地址1',
  Bank_address2: '银行地址2',
  Bank_address3: '银行地址3',
  Beneficiary_Details: '受益人详情',
  Recipient_full_name: '收件人全名',
  Recipient_address1: '收件人地址1',
  Recipient_address2: '收件人地址2',
  Recipient_address3: '收件人地址3',
  Reference: '参考',
  Fiat_deposit: '法定存款',
  Fiat_withdrawl: '法币提款',
  Beneficiary_Accountname: ' 收款人账户名称',
  Innovative_Concepts: '创新概念股份公司',
  Beneficiary_Accountaddress: '收款账户地址',
  PL_DU_BOURG_DE_FOUR: 'PL DU BOURG DE FOUR6,1204 日内瓦，瑞士',
  Beneficiary_account: '受益人帐户',
  for_Domestic_wires: '国内电线用',
  for_international_wires: '对于国际电线',
  Swift_BICcode: 'Swift/BIC 代码',
  SIGNU: '%(值)s',
  beneficiaryBank: '收款银行',
  signature_bank: '签名银行',
  beneficiary_Bank_address: '收款银行地址',
  bank_Reference_No: '银行参考编号',
  Fifth_Avenue: '第五大道 565 号，纽约 NY 10017',
  reference: '参考',
  reference_hint_text: '重要提示：此代码可识别您的存款，请在交易说明或目的中提交电汇时包含此代码',
  desposite_text: '请选择您要存入的货币，然后将显示所选货币的银行帐户详细信息。下一步是您将资金电汇到所提供的银行详细信息，并记住包括参考号。',
  EUR_amount: '欧元金额',
  Fee: '费用',
  Amount_to_pay: '支付数量',
  place_an_order: '下订单',
  Routing_number: '路由号码',
  Choose_a_unique_pass_word_to_protect_your_account: '选择一个唯一的密码来保护您的帐户。',
  Save_btn_text: '保存',
  forgot_your_pass_word: '忘记密码了吗？',
  Require_all_devices_to_signin: '要求所有设备登录 ',
  with_new_pass_word: '使用新密码',
  Yes: '是的',
  summar_info: '概要信息',
  convert: '兌換',
  EUR_text: '请选择您要存入的货币，然后将显示所选货币的银行帐户详细信息。下一步是您将资金电汇到所提供的银行详细信息，并记住包括参考号。',
  BICcode: 'BIC',
  EPUALT: 'EPUALT22XXX',
  account_holder_name: '账户持有人姓名',
  innovative_concepts: '创新理念',
  wire_16_ZBPHXFHI: 'wire_16_ZBPHXFHI',
  note: '请注意，我们接受只能在 SEPA 中寻址的传输。',
  suisse_btn_refresh: "點擊獲取新費率",
  proceed: '行进',
  description: '描述',
  withdrawal_success: '提現成功',
  dep_with_assets: "加密存款/取款",
  remarks: "評論",
  userName: '行进',
  manage_account: '加密存款/取款',
  withdrawCrypto: '提取加密',
  withdrawFiat: '提現法幣',
  fiatAddress: '法定地址',
  cryptoAddress: '添加加密地址',
  change_10Sec_amount: '您的金額可能會在 10 秒內更改',
  tabName_All: '全部',
  Ethereum_Text: '以太坊',
  searchCurrency: '搜索貨幣',
  selectWallet: '選擇錢包',
  final_Amount: '产量只是估计，数量会改变。',
  agree_sell: '我同意 Suissebase 的',
  terms: '服務條款',
  refund_cancellation: '及其退貨、退款和取消政策。',
  purchase_min: '您必須至少購買',
  purchase_max: '您最多只能購買',
  enter_wallet: '請輸入購買金額',
  select_wallet: '請選擇錢包以繼續',
  insufficientFunds: '不充足的資金',
  agree_terms:  "請在閱讀並同意服務條款後單擊上面的複選框，然後再繼續",
  All: '全部',
  BuyandSell: '買賣',
  DepositandFiat: '存款法幣',
  DepositandCrypto: '存入加密貨幣',
  accountType: '帳戶類型',
  Wallet: "錢包",
  RequestDate: '查詢日期',
  walletAddress: '錢包地址',
  coinName: '硬幣名稱',
  availableCoins: '存幣',
  Date: '日期',
  Type: '類型',
  FromWallet: '從錢包',
  FromValue: '從價值',
  Status: '地位',
  ToWallet: '到錢包',
  ToValue: '價值',
  BeforeValue: '值前',
  AfterValue: '後值',
  TotalAmount: '總金額',
  AmountInUsd: '美元金額',
  ReferenceId: '參考 ID',
  Select: '選擇',
  menu_swap1: '交换 ',
  Ethereum: '以太坊',
  balance: '平衡 -',
  ExchangeRate: '匯率',
  Country: '國家',
  state: '狀態',
  zipcode: '郵政編碼',
  ProfileInfo: '個人資料',
  BasicInfo: '基礎信息',
  SelectAddress: '選擇地址',
  account_details: '確保帳戶詳細信息正確無誤',
  Cancel_select: '交易無法取消',
  Confirm: '確認',
  back: '背部 ',
  totalbalance: '總餘額 ',
  select_currency: '在您的錢包中選擇一種貨幣',
  BasicInfotag: '您在 Suissebase 上使用的基本信息，例如您的姓名和照片',
  FirstName: '名',
  MiddleName: '中間名字',
  LastName: '姓',
  Birthday: '生日',
  ReferenceCode: '參考代碼',
  ContactInfo: '聯繫方式',
  EmailAddress: '電子郵件地址',
  PhoneNumber: '電話號碼',
  address_book_tag: '用戶自定義通訊錄',
  AddressLabel: '地址標籤',
  Coin: '硬幣',
  Enteraddresslabel: '輸入地址標籤',
  Enteraddress: '輸入地址',
  AddFiatAddress: '添加 FIAT 地址',
  Selectcoin: '選擇硬幣',
  documents: '文件',
  tab_crypto: '加密',
  tab_fiat: '菲亞特',
  available: '可用的',
  Available: '可用的',
  total_balance: '總餘額',
  wallet_address: '選擇錢包地址',
  enter_address: '輸入地址',
  enter_wallet_address: '請輸入錢包地址',
  enter_amount: '輸入金額',
  amount_less: '輸入的金額應小於可用餘額',
  amount_max: '輸入的金額應小於最大提款金額',
  amount_min: '請輸入最小值',
  tag: '標籤',
  button: '分享',
  select_address: '選擇地址簿',
  searchAddress: '搜索地址標籤',
  oops: '面向對象',
  address_available: '没有可用地址',
  withdrawSummary: '提取加密貨幣摘要',
  selectCurrencyinWallet: '在您的錢包中選擇一種貨幣',
  agree_termsofservice: '請同意服務條款',
  withdraw_crypto: '提取加密貨幣',
  with_draw: "提取",
  liveVarification: '提取加密',
  Withdraw_liveness: '實時驗證',
  TwoFactorAuthentication: '兩因素身份驗證',
  TwoFactorAuthentication_tag: '使用兩步驗證保護您的帳戶',
  FA_tag: '頸背',
  Enabled: '啟用',
  Disabled: '已禁用',
  Password: '密碼',
  Modifiedon: '修改於',
  Click_here_to_change_pass_word: '單擊此處更改密碼',
  current_pass_word: '當前密碼',
  Type_your_current_pass_word: '輸入你當前的密碼',
  Type_your_new_pass_word: '輸入您的新密碼',
  Re_type_your_new_pass_word: '重新輸入您的新密碼',
  new_pass_word: '新密碼',
  confirm_pass_word: '確認密碼',
  User_customized_settings: '用戶自定義設置',
  theme: '主題',
  dark_theme: '黑暗主題',
  light_thme: '輕主題',
  RequestedDate: '申請日期',
  title: '標題',
  Back_to_Swap: '返回交換',
  AuditLogs: '審計日誌',
  TimeSpan: '時間跨度',
  Features: '特徵',
  Last_1_Day: '過去 1 天',
  All_Features: '所有功能',
  search: '搜索',
  feature: '特徵',
  Feature_Path: '特征路徑',
  Action: '行動',
  Custom_Dates: '自定義日期',
  Start_Date: '開始日期',
  End_Date: '結束日期',
  Select_Date: '選擇日期',
  ok: '好的',
  funds_to_swap: '交換資金不足',
  No_data: '沒有找到數據',
  confirm_button: '確認',
  crypto_with_draw_success: '返回提取加密货币',
  new_pass_word_msg: '請輸入新密碼',
  Back_to_Withdraw: '返回提款',
  New_Address: '新地址',
  return_to_depositfiat: '返回存款法幣',
  coin: '硬幣',
  price: '價錢',
  last_24hrs: '過去 24 小時',
  home: '家',
  careers: '職業',
  legal_policy: '法律與政策',
  Deposit_success: '入金成功',
  SelectCurrency: '選擇貨幣',
  select_bank: '選擇銀行名稱 ',
  BankName: '銀行名',
  sellCrypto: '出售加密貨幣',
  pleaseSelectWallet: '請選擇錢包',
  available_amount_less: '輸入的金額應小於可用金額',
  available_balance_less: '輸入的金額應小於餘額',
  enter_minvalue: '請輸入最小值',
  enter_coinvalue: '請從硬幣價值輸入',
  receive_coin: '請選擇接收硬幣進行交換',
  insufficient_balance: '餘額不足',
  select_coin: '請從硬幣中選擇',
  coin_swap: '請選擇要交換的硬幣',
  is_required: '是必須的',
  current_pass_word_msg: '請輸入當前密碼',
  confirm_pass_word_msg: '請輸入確認密碼',
  one_record: '請選擇一項記錄',
  accept_terms: '請接受服務條款',
  amount_greater_zero: '金額必須大於零。',
  exceeded_amount: '您已超過最大金額',
  mkt_Cap: '馬克帽',
  total_volume: '總容積',
  total_supply: '總供應量',
  full_screen: '全屏',
  reload: '重新加載',
  cockpit: '座艙',
  settings_msg: '設置保存成功',
  notification_msg: '没有可用的通知',
  bank_msg: '銀行信息不可用',
  address_msg: '地址保存成功',
  add: '添加',
  edit: '編輯',
  active_inactive: '活動/非活動',
  location: '地點',
  ipAddress: 'IP地址',
  menu_wallets: "餘額",
  crypto_wallets: "加密钱包",
  verification_code: "輸入驗證碼",
  get_code: "獲取一次性密碼",
  resend_code: "重新發送驗證碼",
  digit_code: "輸入 6 位代碼發送至",
  selectcurrency: "选择货币",
  bank_account_number: '银行帐号',
  invalid_code: '无效的代码',
  resend_text: '2 分鐘後單擊重新發送按鈕',
  please_enter_valid_content: '请输入有效内容',
  W: '宽',
  M: '米',
  Y: '是',
  copied: '複製的',
  withdraw_fiat_summary: '提現法幣摘要',
  request_fail: '已達到最大嘗試次數，請稍後再試',
  received: "已收到",
  comssion: "委员会",
  thousandKText: "最高金额为 10 万美元。",
  sucessText1: "您的订单已成功下单，",
  sucessText2: "已添加到您的钱包中。",
  sucessText3: "金额已从您的钱包中扣除",
  value_should_greater: "從硬幣價值必須大於零",
  asset_directory: '资产目录',
  you_are_sending: '您正在发送',
  fees: '费用',
  you_receive: '您将收到',
  company_name: '公司名',
  withdrawal_verification: '提款驗證',
  Phone_verification: '電話驗證',
  Email_verification: '電子郵件驗證',
  addresslabel: '地址標籤',
  address_type: 'Address Type',
  addressState: "地址状态",
  add_new_beneficiary: 'Add New Beneficiary',
  kyc_documents: 'KYC Documents',
  confirm_beneficiary: 'Confirm Beneficiary',
  return_to_beneficairy: 'Back to Add New Beneficiary',
  compliance: '(Compliance)',
  upload_documents: 'Upload Documents',
  get_your_own_iban: '获取自己的 IBAN',
  case: '案子',
  CaseNumber: '案件編號',
  CaseTitle: '案例名稱',
  referral: 'Referral',
  referral_id:'Referral Id',
  referral_link:'Referral Link',
  bank_type: "银行类型",
  iban: "伊班",
  Bank_Transaction_verification:"銀行轉賬驗證",
  Live_Verification:"實時驗證",

}