import { apiMain } from "./clients";
import { ApiControllers } from "./config";
import { store } from "../store";
import counterpart from "counterpart";
import CryptoJS from "crypto-js";

const getMember = (useremail) => {
	return apiMain.get(ApiControllers.customers + useremail);
};
const ProfileImageSave = (obj) => {
	return apiMain.put(ApiControllers.customers + "Avatar", obj);
};
const trackEvent = (obj) => {
	const {
		userConfig: { userProfileInfo, trackAuditLogData },
	} = store.getState();
	let trackObj = {
		id: "00000000-0000-0000-0000-000000000000",
		date: "",
		type: obj.Type,
		featurePath: obj.FullFeatureName,
		username: obj.userName,
		customerId: userProfileInfo?.id,
		feature: obj.Feature,
		action: obj.Action,
		remarks: obj.Remarks,
		ipAddress: trackAuditLogData?.Ip,
		countryName: trackAuditLogData?.Location?.countryName,
		info: JSON.stringify(trackAuditLogData),
	};
	return apiMain.post(ApiControllers.master + `Auditlogs`, trackObj);
};
const getSettingsLuData = () => {
	return apiMain.get(ApiControllers.customers + "Settings");
};
const saveSettingsData = (obj) => {
	return apiMain.post(ApiControllers.customers + "Settings", obj);
};
const downloadKyc = (customerId) => {
	return apiMain.get(ApiControllers.customers + `DownloadFile/${customerId}`);
};
const updateSecurity = (obj) => {
	return apiMain.put(ApiControllers.master + "UpdateSecurity", obj);
};

const verifySecurity = (obj) => {
	return apiMain.post(ApiControllers.bank + "TransferSecurity", obj);
};

const changePassword = (obj) => {
	return apiMain.put(ApiControllers.customers + "ChangePassword", obj);
};

const convertLocalLang = (key) => {
	return counterpart.translate(key);
};

const encryptValue = (msg, key) => {
	msg = typeof msg == "string" ? msg : JSON.stringify(msg);
	let salt = CryptoJS.lib.WordArray.random(128 / 8);

	let key1 = CryptoJS.PBKDF2(key, salt, {
		keySize: 256 / 32,
		iterations: 10,
	});

	let iv = CryptoJS.lib.WordArray.random(128 / 8);

	let encrypted = CryptoJS.AES.encrypt(msg, key1, {
		iv: iv,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
	});
	return salt.toString() + iv.toString() + encrypted.toString();
};

const getIBANData = (ibannumber) => {
	return apiMain.get(
		ApiControllers.master + `GetIBANAccountDetails?ibanNumber=` + ibannumber
	);
};

const getVerificationFields = (customerId) => {
	return apiMain.get(ApiControllers.master + `Verificationfields/${customerId}`);
};

const getReferalDetails = (customerId) => {
	return apiMain.get(ApiControllers.partner + `getReferralDetails/customer/${customerId}`);
}
export {
	getMember,
	ProfileImageSave,
	trackEvent,
	getSettingsLuData,
	saveSettingsData,
	downloadKyc,
	updateSecurity,
	changePassword,
	encryptValue,
	convertLocalLang,
	getIBANData,
	getVerificationFields,
	getReferalDetails,
	verifySecurity
};
