import React, { Component } from 'react';
import { Tabs } from 'antd'
import ProfileInfo from './profileInfo';
import Security from './security'
import AddressBook from '../addressbook.component';
import QueryString from 'query-string'
import Settings from './settings';
import Cases from '../case.component/cases';
import Translate from 'react-translate-component';
import { connect } from 'react-redux';
import { addressTabUpdate } from '../../reducers/addressBookReducer'
import { setHeaderTab } from "../../reducers/buysellReducer"
import Referral from "../referral.component/referral";
const { TabPane } = Tabs;
class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProfile: false,
            isSecurity: false,
            isSetting: false,
            tabPosition: 'left',
            activeTab: this.props.match.params.key ? this.props.match.params.key : "profileInfo",
            activeWithdrawFiat: false
        }
    }
    componentDidMount() {
        debugger
        if (this.props?.match?.path === '/userprofile') {
            this.props.dispatch(setHeaderTab(""));
        }
        let activeKey = QueryString.parse(this.props.history.location.search)?.key;
        if (activeKey) {
            this.setState({ ...this.state, activeTab: activeKey });
        }
        if (this.props.match.params?.name) {
            this.changeTab(this.props.match.params?.name);
        } else {
            this.setState({ activeTab: this.state.activeTab });
            this.changeTab(this.state?.activeTab);
        }
    }
    handleProfile = () => {
        this.setState({ isProfile: true })
    }
    handleSecurity = () => {
        this.setState({ isSecurity: true, isProfile: false })
    }
    handleSetting = () => {
        this.setState({ isSetting: true, isProfile: false })
    }

    changeTab = activeKey => {
        debugger
        this.setState({ ...this.state, activeTab: activeKey }, () => {
            if (this.props.userConfig?.role === "Customer") {
                if (this.state.activeTab === "profileInfo") {
                    this.props.history.push("/userProfile/profileInfo");
                }
                else if (this.state.activeTab === 'security') {
                    this.props.history.push("/userProfile/security");
                }
                else if (this.state.activeTab === 'settings') {
                    this.props.history.push("/userProfile/settings");
                }
                // else if (this.state.activeTab === 'addressBook') {
                // 	this.props.history.push("/userProfile/addressBook");
                // }
                // else if (this.state.activeTab === 'case') {
                // 	this.props.history.push("/userProfile/case");
                // }
                else if (this.state.activeTab === 'referral') {
                    this.props.history.push("/userProfile/referral");
                }
            } else if (this.props.userConfig?.role !== "Customer") {
                if (this.state.activeTab === "profileInfo") {
                    this.props.history.push("/userProfile/profileInfo");
                }
                else if (this.state.activeTab === 'security') {
                    this.props.history.push("/userProfile/security");
                }
            }

        })
    }

    render() {

        if (this.props.addressBookReducer.addressTab) {
            this.setState({ ...this.state, activeTab: "case", activeWithdrawFiat: true });
            this.props.dispatch(addressTabUpdate(false));
        }

        const { tabPosition } = this.state;
        const tabPane = [
            {
                key: "profileInfo",
                icon: "icon lg profile-icon mr-16",
                title: <Translate content="ProfileInfo" component={Tabs.TabPane.tab} />,
                component: <ProfileInfo />,
                admin: true,
            },
            {
                key: "security",
                icon: "icon lg security-icon mr-16",
                title: <Translate content="security" className="f-16  mt-16" />,
                component: <Security />,
                admin: true,
            },
            {
                key: "settings",
                icon: "icon lg settings-icon mr-16",
                title: <Translate content="settings" className="f-16  mt-16" />,
                component: <Settings />,
                admin: false,
            },
            // {
            // 	key: "addressBook",
            // 	icon: "icon lg addressbook-icon mr-16",
            // 	title: (
            // 		<Translate content="address_book" component={Tabs.TabPane.tab} />
            // 	),
            // 	component: <AddressBook />,
            // 	admin: false,
            // },
            // {
            // 	key: "case",
            // 	icon: "icon lg cases-icon mr-16",
            // 	title: <Translate content="case" className="f-16  mt-16" />,
            // 	component: <Cases />,
            // 	admin: false,
            // },
            {
                key: "referral",
                icon: "icon lg referral-icon mr-16",
                title: <Translate content="referral" className="f-16  mt-16" />,
                component: <Referral />,
                admin: false,
            },
        ];
        const filteredTabPane = tabPane.filter((item) => {
            if (this.props.userConfig.role === "Customer") {
                return item.key !== "0";
            } else {
                return item.admin === true;
            }
        });


        return (
            <>
                <div className="main-container hidden-mobile">
                    <Tabs
                        tabPosition={tabPosition}
                        className="user-list"
                        activeKey={this.state.activeTab}
                        onChange={this.changeTab}
                    >
                        {filteredTabPane.map((item) => (
                            <TabPane
                                tab={
                                    <span>
                                        <span className={item.icon} />
                                        {item.title}
                                    </span>
                                }
                                key={item.key}>
                                {item.component}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </>
        );

    }
}
const connectStateToProps = ({ userConfig, addressBookReducer }) => {
    return { addressBookReducer, userConfig: userConfig.userProfileInfo }
}
export default connect(
    connectStateToProps,
)(UserProfile);
