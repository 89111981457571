import { Component } from "react";
import {  } from '../api/apiCalls';

class Home extends Component{


    render(){
        return "Home"
    }
}

export default Home;