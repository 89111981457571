import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../components/home";
import CallbackPage from "../auth/callback.component";
import OnBoarding from "./onboard.component";
import UserProfile from "../components/userProfile.component/userProfile";
import AddressCryptoView from "../components/addressbook.component/addressCryptoView"
import AddressFiatView from "../components/addressbook.component/addressFiatView"
//  import RequestedDocs from '../components/case.component/caseView'

class RouteConfig extends Component {
	render() {
		return (
			<>
				<Switch>
					<Route exact path="/" component={UserProfile} />
					<Route exact path="/home" component={UserProfile} />
					<Route path="/callback" component={CallbackPage} />
					<Route path="/onboading" component={OnBoarding} />
					<Route path="/userProfile/:name?" component={UserProfile} />
					<Route path='/addressFiatView/:id' component={AddressFiatView} />
                    <Route path='/addressCryptoView/:id' component={AddressCryptoView} />
					{/* <Route path='/cases' component={RequestedDocs} /> */}
				</Switch>
			</>
		);
	}
}

export default RouteConfig;
