import { apiMain, firebaseServer } from "./clients";
import { ApiControllers } from "./config";

export const changePassword = (obj) => {
	return apiMain.put(ApiControllers.customers + "ChangePassword", obj);
};
export const withdrawRecepientNamecheck = (customerId, name) => {
	return apiMain.get(
		ApiControllers.depositWithdraw +
		"FavouriteNameCheck?customerId=" +
		customerId +
		"&name=" +
		name
	);
};
export const getCountryStateLu = () => {
	return apiMain.get(
		ApiControllers.master + "ControlCodes?codeCategory=country"
	);
};
export const getStateLookup = (country) => {
	return apiMain.get(ApiControllers.master + "States?countryName=" + country);
};
export const withdrawSave = (obj) => {
	return apiMain.post(ApiControllers.depositWithdraw + "Withdraw/Fiat", obj);
};
export const ProfileImageSave = (obj) => {
	return apiMain.put(ApiControllers.customers + "Avatar", obj);
};
export const getSettingsLuData = () => {
	return apiMain.get(ApiControllers.customers + "Settings");
};
export const saveSettingsData = (obj) => {
	return apiMain.post(ApiControllers.customers + "Settings", obj);
};
