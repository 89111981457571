import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Auth from "./auth";
import AppLayout from "./layout";
import { store } from "./store";
import { loadUser, OidcProvider } from "redux-oidc";
import { userManager } from "./auth/index";
import {ThemeSwitcherProvider} from 'react-css-theme-switcher'

function App() {
	const [isLoggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		localStorage.setItem("__url", window.location.pathname);
		loadUser(store, userManager).then((user) => {
			setLoggedIn(true);
		});
	}, []);
	return (
		<ThemeSwitcherProvider
			defaultTheme="DRT"
			themeMap={{ DRT: "./dark-theme.css", LHT: "./light-theme.css" }}>
			<Provider store={store}>
				<OidcProvider userManager={userManager} store={store}>
					<BrowserRouter>
						{isLoggedIn ? (
							<AppLayout />
						) : (
							<div className="loader">Loading....</div>
						)}
					</BrowserRouter>
				</OidcProvider>
			</Provider>
		</ThemeSwitcherProvider>
	);
}

export default App;
