import { create } from "apisauce";
import CryptoJS from "crypto-js";
import { store } from "../store";

const apiClient = create({
	baseURL: process.env.REACT_APP_CARDS_API_END_POINT,
});
const apiMain = create({
	baseURL: process.env.REACT_APP_API_END_POINT,
});
const uploadClient = create({
	baseURL: process.env.REACT_APP_UPLOAD_API,
});

const _encrypt = (msg, key) => {
	msg = typeof msg == "object" ? JSON.stringify(msg) : msg;
	var salt = CryptoJS.lib.WordArray.random(128 / 8);

	key = CryptoJS.PBKDF2(key, salt, {
		keySize: 256 / 32,
		iterations: 10,
	});

	var iv = CryptoJS.lib.WordArray.random(128 / 8);

	var encrypted = CryptoJS.AES.encrypt(msg, key, {
		iv: iv,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
	});
	return salt.toString() + iv.toString() + encrypted.toString();
};
// apiClient.axiosInstance.interceptors.request.use((config) => {
//     const { auth: { user }, userConfig: { userProfileInfo } } = store.getState()
//     config.headers.Authorization = `Bearer ${user.access_token}`
//     if (userProfileInfo?.id) config.headers.AuthInformation = userProfileInfo?.id ? _encrypt(`{customerId:"${userProfileInfo?.id}"}`, userProfileInfo.sk) : ''
//     return config;
// })

apiMain.axiosInstance.interceptors.request.use((config) => {
	const {
		auth: { user },
		userConfig: { userProfileInfo },
	} = store.getState();
	config.headers.Authorization = `Bearer ${user.access_token}`;
	if (userProfileInfo?.id)
		config.headers.AuthInformation = userProfileInfo?.id
			? _encrypt(`{customerId:"${userProfileInfo?.id}"}`, userProfileInfo.sk)
			: "";
	return config;
});
export { apiClient, apiMain, uploadClient };
