export const ApiControllers = {
	cards: "Cards/",
	accounts: "Accounts/",
	customers: "Customer/",
	master: "Master/",
	member: "Member/",
	documents: "Documents/",
	depositWithdraw: "DepositeWithdraw/",
	buySell: "Buysell/",
	markets: "Markets/",
	wallets: "Wallets/",
	partner: "Partner/",
	bank: "Bank/",
};
