export default {
  header_title: 'Sabungan',
  user_type: '%(lable)s',
  Portfolio_title: 'Portfolio Crypto',
  your_portfolio: 'Portfolio anda',
  suissebase_title: 'Pangkalan Suissebase',
  suissebase_subtitle: 'Timbangan Fiat',
  markets_title: 'Pasaran',
  markets_subtitle: 'Mana-mana mata wang dengan Berdagang Serie Anda',
  deposit: 'simpanan',
  withdraw: 'menarik diri',
  buy: 'Beli',
  sell: 'Jual',
  load_more: 'memuatkan lebih banyak ...',
  ftr_home: 'Rumah',
  ftr_careers: 'Kerjaya',
  ftr_tc_p: 'Legan & Dasar',
  search_currency: 'Cari Mata Wang',
  buy_assets: 'Beli Aset',
  // past_hours: 'Dalam 24 jam yang lalu',
  deposite_a_crypto: 'Depositkan Crypto',
  deposite_a_cryto_txt: 'Dapatkan crypto dengan hanya berkongsi Alamat QR anda',
  purchase_a_crypto: 'Beli Crypto anda dengan Wang Tunai',
  // purchase_a_cryto_txt: 'Dompet anda kosong, anda tidak mempunyai aset untuk membuat transaksi. Ikuti pautan ini dan Deposit sejumlah wang.',
  all: 'semua',
  tabs_All: 'Lahat',
  gainers: 'peraih',
  losers: 'orang yang kalah',
  db_slider_title: 'Get $10 in free crypto',
  db_slider_desc: 'Sediakan pembelian berulang $ 50 atau lebih dan dapatkan $ 10 setelah kedua-dua pembelian ke-4 dan ke-8 anda',
  db_slider_btn: 'Siapkan pembelian berulang',
  selected_crypto: 'Beli eth',
  crypto_compare_val: '1 ETH - $2,495.31 ',
  find_with_wallet: 'Pilih dompet anda',
  refresh_newprice: 'Segarkan untuk mendapatkan harga baru',
  confirm_btn_text: 'Sahkan %(counter)s',
  exchange_rate: 'Kadar pertukaran',
  amount: 'Jumlah',
  suissebase_fee: 'Yuran Suissebase',
  estimated_total: 'Anggaran Jumlah',
  summary_hint_text: 'Jumlah akhir anda mungkin berubah dalam masa 10 saat.',
  pay: 'Bayar %(counter)s',
  cancel: 'Batal',
  link_a_card: 'Pautkan Kad atau Terima',
  select_from_below: 'Pilih dari bawah',
  credit_card: 'Kad kredit',
  credit_card_text: 'Gunakan kad kredit atau debit',
  desosit_text: 'Deposit dari alamat atau dompet yang ada',
  name_on_card: 'Nama pada kad',
  card_number: 'Nombor kad',
  expiry: 'Tamat tempoh',
  cvv: 'CVV',
  type_billing_address: 'Taipkan alamat pengebilan anda',
  change_billing_address: 'Tukar Alamat Pengebilan',
  confirm_billing_address: 'Sahkan Alamat Pengebilan',
  billing_address: 'Alamat Pengebilan',
  delivery_address: 'Alamat penghantaran anda',
  select_a_currency: 'Pilih mata wang',
  address: 'Alamat',
  address_hint_text: 'Pastikan alamat penghantaran anda betul',
  copy: 'Salinan',
  share: 'Berkongsi',
  from_crypto: 'Dari Crypto',
  from_fiat: 'Dari Fiat',
  wire_transfer_mthd: 'Kaedah pemindahan wayar',
  recommended: 'Disyorkan',
  select_a_method: 'Pilih kaedah',
  sepa: 'sepa',
  wire_transfer: 'Pindahan wayar',
  swift: 'pantas',
  no_fee: 'Tiada yuran',
  sell_btc: "Jual BTC",
  select_wallet1: "jual dari dompet anda",
  confirm_sell: "Sahkan menjual Crypto anda",
  sell_compare_val: '1 BTC - $34,818.98',
  preview: 'Pratonton %(counter)s',
  success_msg: "Terima kasih",
  success_decr: "Pesanan anda telah berjaya",
  confirm_msg: 'Menunggu pengesahan',
  confirm_decr: "Pesanan anda telah dibuat Sahkan",
  withdraw_a_crypto: "Tarik Kripto",
  withdraw_a_crypto_text: 'Tarik balik dan depositkan untuk dompet crypto anda.',
  usd_available: "USD 106.79 ada",
  send_wallet_fiat: 'Hantar dari dompet anda dan ke fiat',
  suissebase_personal: 'Suissebase Peribadi',
  avail_wallet_weprovide: 'Dompet yang ada kami sediakan',
  withdraw_Btc: 'Tarik balik BTC',
  withdraw_summary_sub: 'Hantarkan alamat mata wang anda.',
  verify_identity: 'Sahkan Identiti anda',
  swap_title: 'Pertukaran Baru',
  swap_desc: 'tukar kripto anda dengan segera ke dalam mata wang yang kami tawarkan di dompet anda',
  swap_from: 'Tukar Dari',
  swap_to: 'Untuk menerima',
  wallet_in_usd: 'Dompet masuk USD',
  purchase_fiat: 'Beli Fiat',
  purchase_fiat_text: 'Dompet anda kosong, anda tidak mempunyai aset untuk membuat transaksi. Ikuti pautan ini dan Deposit sejumlah wang.',
  link_newcard: 'Paut kad baru',
  pick: 'pilih',
  confirm_swap: 'Sahkan Pertukaran',
  total: 'jumlah',
  confirm_transaction: 'Sahkan Transaksi',
  confirm_transaction_decs: 'Dompet dalam USD',
  add_fund: 'Tambah dana',
  confirm_text: 'Pertukaran 0.00549635 BTC untuk 0.10183318 ETH',
  deposite_Btc: 'Tambah dana',
  sell_your_crypto_for_cash: 'Jual Crypto anda dengan Wang Tunai',
  sell_your_crypto_for_cash_text: 'Beli dan jual Crypto dengan mudah terus dari Wallet anda.',
  sell_your_fiat_for_cash: 'Jual Fiat anda dengan Wang Tunai',
  menu_buy_sell: 'Jual beli',
  menu_transactions_history: 'Urus Niaga',
  menu_swap: 'Tukar',
  menu_send_receive: 'Deposito / Pengeluaran',
  menu_mass_pay: 'Pembayaran Massa',
  menu_payments: 'Pembayaran',
  security: 'Keselamatan',
  current_balance: 'Baki terkini',
  min: 'Min',
  half: 'Separuh',
  assets: 'Aset',
  confirm_now: 'Sahkan Sekarang %(counter)s',
  buy_sell_address: 'UNIT 527 TOWER 4, SMDC Grace Residences, Cayetano Blvd. Brgy. Ususan, Taguig City 1630 PH',
  withdraw_to: 'Tarik balik ke',
  btc_address: 'Alamat Binance BTC',
  fund_with_card: 'Dana dengan kad',
  fund_with_card_text: 'Berapa banyak yang anda mahu tambah?',
  preview_swap: 'Pertukaran Pratonton',
  deposit_link: 'Saya bersetuju dengan Suissebase %(link)s',
  link: 'link',
  agree_to_suissebase: 'Saya bersetuju dengan Suissebase %(link)s dan polisi pengembalian, pembayaran balik dan pembatalannya.',
  terms_service: 'Syarat Perkhidmatan',
  return_to_buy: 'Kembali ke Beli',
  return_to_sell: 'Kembali ke Jual',
  fidor_bank: 'Fidor Bank AG',
  transaction_submitted: 'Transaksi Dihantar',
  swapped_btc: 'Tukar 0.00549635 BTC untuk 0.10183318 ETH',
  bank_frick: 'Bank Frick',
  scan_your_crypto: 'Imbas kad anda BTC',
  center_qr: 'Pusatkan Pengimbas QR',
  close: 'Tutup',
  card_supported: 'Jenis kad tidak disokong',
  need_to_replenish: 'Perlu mengisi semula dompet anda? Ikuti pautan ini dan %(link)s sejumlah wang.',
  lets_verified: "Mari dapatkan pengesahan anda",
  confirm_your_identity: 'Suissebase.ch perlu mengesahkan identiti anda bahawa proses dikuasakan oleh pasangan kami.',
  prepare_valid: 'Sediakan dokumen yang sah',
  make_sure: "Pastikan tidak luput dari kerosakan fizikal",
  user_smartphone: 'Pengguna telefon pintar',
  you_need_smartphone: 'Anda memerlukan telefon pintar untuk meneruskan',
  qption1: 'Pilihan 1: Imbas kod QR',
  qption1_answer: 'Imbas kod QR dengan aplikasi kamera anda',
  qption2: 'Pilihan 2: Hantar pautan melalui SMS',
  qption2_answer: 'Kami akan menghantar pautan selamat ke telefon bimbit anda tanpa kos tambahan.',
  return_to_fiat: 'Kembali ke Fiat',
  admin_fee: 'Bayaran Pentadbiran',
  free: 'Percuma',
  to: 'Ke',
  from: 'Dari',
  current_security_level: 'TAHAP KESELAMATAN SEMASA',
  current_security_text: 'Akaun anda mempunyai ciri keselamatan yang dimatikan, sehingga berpotensi rentan terhadap serangan tertentu. Sediakan ciri keselamatan ini untuk meningkatkan keselamatan akaun anda.',
  medium: 'sederhana',
  change_pass_word: 'Tukar kata laluan',
  protect_your_account: 'Lindungi akaun anda',
  settings: 'Tetapan',
  general: 'Am',
  privacy_policy: 'Dasar Privasi',
  logout: 'Log keluar',
  themes: 'Tema',
  notifications: 'Pemberitahuan',
  local_currency: 'Mata wang tempatan',
  language: 'Bahasa',
  preferences: 'Keutamaan',
  wallet_version: 'Versi Dompet',
  about: 'Mengenai',
  USD_wallet: 'Dompet USD',
  start: 'Mulakan',
  start_text: 'Kami adalah platform yang menghubungkan bank, sistem pembayaran, dan orang.',
  personal: 'Peribadi',
  wallets: 'Dompet',
  wallets_text: 'Kawalan penuh kunci peribadi anda.',
  cards: 'Kad',
  exchange: 'Pertukaran',
  business: 'Perniagaan',
  business_text: 'Pengguna boleh membuat akaun berasingan seperti sistem dompet berasingan korporat.',
  crypto: 'Kripto',
  corporate_wallet: 'Dompet Korporat',
  connect: 'Sambung',
  meet_our_team: 'Temui Pasukan Kami',
  report_a_bug: 'Laporkan Bug',
  FAQ: 'Soalan Lazim',
  contact_us: 'Hubungi Kami',
  sign_in: 'Log masuk',
  security_text: 'Akaun anda mempunyai ciri keselamatan yang dimatikan, sehingga berpotensi rentan terhadap serangan tertentu. Sediakan ciri keselamatan ini untuk meningkatkan keselamatan akaun anda.',
  spend: 'Berbelanja',
  spend_text: 'Akaun anda mempunyai ciri keselamatan yang dimatikan, sehingga berpotensi rentan terhadap serangan tertentu. Sediakan ciri keselamatan ini untuk meningkatkan keselamatan akaun anda.',
  chat: 'Berbual',
  current_security: 'TAHAP KESELAMATAN SEMASA',
  status: 'untuk melihat status anda',
  michael_quiapos: 'Michael Quiapos',
  great: 'Hebat. saya akan melihat ...',
  address_book: 'Buku alamat',
  invite_friends: 'Menjemput kawan-kawan',
  light_theme: 'Tema Cahaya',
  localization: 'Penyetempatan',
  localization_text: 'Pengguna boleh membuat akaun berasingan seperti sistem dompet berasingan korporat.',
  lang: 'lang',
  currency: 'Mata Wang',
  support: 'Sokongan',
  help_center: 'Pusat Bantuan',
  social_networks: 'Rangkaian sosial',
  medium_text: 'TAHAP KESELAMATAN SEMASA %(pautan)s untuk melihat status anda',
  backup_wallet: 'Dompet Sandaran',
  reset_wallet: 'Tetapkan semula Dompet',
  always_ask_pin: 'Selalu tanya pin',
  activate_face: 'Aktifkan ID wajah',
  activate_biometry: 'Aktifkan biometri',
  buy_and_sell: 'Beli dan jual',
  swap_services: 'Perkhidmatan Pertukaran',
  deposit_and_withdraw: 'Deposit dan Pengeluaran',
  the_dashboard: 'Papan Pemuka',
  buy_crypto: 'Beli Crypto',
  Beneficiary_BankDetails: 'Butiran Bank Penerima',
  Bank_account: 'Nombor akaun bank / IBAN',
  BIC_SWIFT_routing_number: 'BIC / SWIFT / Nombor laluan',
  Bank_name: 'nama bank',
  Bank_address1: 'Alamat bank1',
  Bank_address2: 'Alamat bank2',
  Bank_address3: 'Alamat bank3',
  Beneficiary_Details: 'Butiran Waris',
  Recipient_full_name: 'Nama penuh penerima',
  Recipient_address1: 'Alamat penerima1',
  Recipient_address2: 'Alamat penerima2',
  Recipient_address3: 'Alamat penerima3',
  Reference: 'Rujukan',
  Fiat_deposit: 'Deposito Fiat',
  Fiat_withdrawl: 'Pengeluaran Fiat',
  Beneficiary_Accountname: ' Nama Akaun Penerima',
  Innovative_Concepts: 'KONSEP INOVATIF AG',
  Beneficiary_Accountaddress: 'Alamat Akaun Penerima',
  PL_DU_BOURG_DE_FOUR: 'PL DU BOURG DE FOUR6,1204 GENEVE, SWITZERLAND',
  Beneficiary_account: 'Akaun Penerima',
  for_Domestic_wires: 'Untuk Wayar Domestik',
  for_international_wires: 'Untuk Wayar Antarabangsa',
  Swift_BICcode: 'Kod Swift / BIC',
  SIGNU: '%(value)s',
  beneficiaryBank: 'Bank Penerima',
  signature_bank: 'Bank Tandatangan',
  beneficiary_Bank_address: 'Alamat Bank Penerima',
  bank_Reference_No: 'No. Rujukan Bank',
  Fifth_Avenue: '565 Fifth Avenue, NEW YORK NY 10017',
  reference: 'Rujukan',
  reference_hint_text: 'Penting: kod ini mengenal pasti deposit anda termasuk kod ini semasa menyerahkan kiriman wang dalam keterangan atau tujuan transaksi',
  desposite_text: 'Pilih mata wang yang ingin anda masukkan, dan butiran akaun bank untuk mata wang yang dipilih akan dipaparkan. Langkah seterusnya adalah untuk memasukkan wang anda ke butiran bank yang disediakan dan ingat untuk MEMASUKI nombor rujukan.',
  EUR_amount: 'Jumlah EUR',
  Fee: 'Bayaran',
  Amount_to_pay: 'Amaun yang perlu dibayar',
  place_an_order: 'membuat pesanan',
  Routing_number: 'Nombor Laluan',
  Choose_a_unique_pass_word_to_protect_your_account: 'Pilih kata laluan yang unik untuk melindungi akaun anda.',
  Save_btn_text: 'Jimat',
  forgot_your_pass_word: 'Lupa kata laluan anda?',
  Require_all_devices_to_signin: 'Memerlukan semua peranti untuk log masuk ',
  with_new_pass_word: 'dengan kata laluan baru',
  Yes: 'Ya',
  summar_info: 'Maklumat Ringkasan',
  convert: 'Tukarkan',
  EUR_text: 'Pilih mata wang yang ingin anda masukkan, dan butiran akaun bank untuk mata wang yang dipilih akan dipaparkan. Langkah seterusnya adalah untuk memasukkan wang anda ke butiran bank yang disediakan dan ingat untuk MEMASUKI nombor rujukan.',
  BICcode: 'BIC',
  EPUALT: 'EPUALT22XXX',
  account_holder_name: 'Nama Pemegang Akaun',
  innovative_concepts: 'Konsep Inovatif',
  wire_16_ZBPHXFHI: 'wayar_16_ZBPHXFHI',
  note: 'Harap maklum bahawa kami menerima pemindahan yang dapat dialamatkan hanya di SEPA.',
  suisse_btn_refresh: "Klik untuk mendapatkan harga baru",
  proceed: 'magpatuloy',
  description: 'Paglalarawan',
  withdrawal_success: 'Umatras ng tagumpay',
  dep_with_assets: "Deposito / Pengeluaran Kripto",
  remarks: 'sinabi',
  userName: 'John Huh',
  manage_account: 'PAMAHALAAN ANG IYONG ACCOUNT',
  withdrawCrypto: 'Bawiin ang Crypto',
  withdrawFiat: 'Bawiin ang Fiat',
  fiatAddress: 'idagdag Fiat Address',
  cryptoAddress: 'Magdagdag ng Crypto Address',
  change_10Sec_amount: 'Ang iyong halaga ay maaaring mabago sa loob ng 10 seg',
  tabName_All: 'Lahat',
  Ethereum_Text: 'Ethereum',
  searchCurrency: 'Pera sa Paghahanap',
  selectWallet: 'Piliin ang Wallet',
  final_Amount: 'Output hanya dianggarkan, jumlah akan berubah.',
  agree_sell: 'Sumasang-ayon ako sa Suissebase',
  terms: 'Mga palatuntunan',
  refund_cancellation: 'at ang patakaran sa pagbabalik, refund at pagkansela nito.',
  purchase_min: 'Kailangan mong bumili ng hindi bababa sa',
  purchase_max: 'Maaari ka lamang bumili ng maximum na',
  enter_wallet: 'Mangyaring maglagay ng halagang bibilhin',
  select_wallet: 'Mangyaring pumili ng wallet upang magpatuloy',
  insufficientFunds: 'Hindi sapat na pondo',
  agree_terms:"Paki-click ang checkbox sa itaas pagkatapos basahin at sumang-ayon sa Mga Tuntunin ng Serbisyo bago magpatuloy",
  All: 'Lahat',
  BuyandSell: 'Bili benta',
  DepositandFiat: 'Pag-deposito Fiat',
  DepositandCrypto: 'Deposito Crypto',
  accountType: 'Uri ng Account',
  Wallet: 'Wallet',
  RequestDate: 'Petsa ng Kahilingan',
  walletAddress: 'Address sa Wallet',
  coinName: 'Pangalan ng Barya',
  availableCoins: 'Mga Deposit na Barya',
  Date: 'Petsa',
  Type: 'Uri',
  FromWallet: 'Mula sa Wallet',
  FromValue: 'Mula sa Halaga',
  Status: 'Katayuan',
  ToWallet: 'Sa Wallet',
  ToValue: 'Halaga',
  BeforeValue: 'Bago ang Halaga',
  AfterValue: 'Pagkatapos ng Halaga',
  TotalAmount: 'Kabuuang Halaga',
  AmountInUsd: 'Halaga Sa Usd',
  ReferenceId: 'Reference Id',
  Select: 'Pumili',
  menu_swap1: 'Tukar ',
  Ethereum: 'Ethereum',
  balance: 'Balanse -',
  ExchangeRate: 'Exchange Rate',
  Country: 'Bansa',
  state: 'estado',
  zipcode: 'zipcode',
  ProfileInfo: 'Impormasyon sa Profile',
  BasicInfo: 'Pangunahing Impormasyon',
  SelectAddress: 'Piliin angAddress',
  account_details: 'Tiyaking tama ang mga detalye ng account',
  Cancel_select: 'Hindi maaaring kanselahin ang transaksyon',
  Confirm: 'Kumpirmahin',
  back: 'Pabalik',
  totalbalance: 'Kabuuang balanse',
  select_currency: 'Pumili ng pera sa iyong wallet',
  BasicInfotag: 'Pangunahing Impormasyon, tulad ng iyong pangalan at larawan, na ginagamit mo sa Suissebase',
  FirstName: 'Pangalan',
  MiddleName: 'Gitnang pangalan',
  LastName: 'Huling pangalan',
  Birthday: 'kaarawan',
  ReferenceCode: 'Reference Code',
  ContactInfo: 'Impormasyon sa Pakikipag-ugnayan',
  EmailAddress: 'Email Address',
  PhoneNumber: 'Numero ng telepono',
  address_book_tag: 'Naka-customize na address book ng user',
  AddressLabel: 'Label ng tirahan',
  Coin: 'barya',
  Enteraddresslabel: 'Ilagay ang label ng tirahan',
  // Enteraddress: 'Ilagay ang address',
  Enteraddress: 'Ilagay ang tirahan',
  AddFiatAddress: 'MAGDAGDAG NG FIAT ADDRESS',
  Selectcoin: 'Piliin ang Coin',
  documents: 'Mga dokumento',
  tab_crypto: 'crypto',
  tab_fiat: 'Fiat',
  available: 'Tersedia',
  Available: 'Tersedia',
  total_balance: 'Kabuuang balanse',
  wallet_address: 'Pumili ng pitaka tirahan',

  enter_address: 'Ilagay ang address',
  enter_wallet_address: 'Pakilagay ang wallet address',
  enter_amount: 'Ipasok ang halaga',
  amount_less: 'Ang inilagay na halaga ay dapat na mas mababa sa available na balanse',
  amount_max: 'Ang inilagay na halaga ay dapat na mas mababa sa max na halaga ng withdraw ng',
  amount_min: 'Pakilagay ang min value ng',
  tag: 'Tag',
  button: 'Ibahagi',
  select_address: 'piliin ang tirahan',
  searchAddress: 'Maghanap ng label ng tirahan',
  oops: 'OOPS',
  address_available: 'Walang magagamit na address',
  withdrawSummary: 'withdraw ang Buod ng Crypto',
  selectCurrencyinWallet: 'Pumili ng pera sa iyong wallet',
  agree_termsofservice: 'Mangyaring sumang-ayon sa mga tuntunin ng serbisyo',
  withdraw_crypto: 'I-withdraw ang crypto',
  with_draw: "Mag-withdraw-",
  liveVarification: 'I-withdraw ang Crypto',
  Withdraw_liveness: 'Live na pag-verify',
  TwoFactorAuthentication: 'Two Factor Authentication',
  TwoFactorAuthentication_tag: 'Protektahan ang iyong account gamit ang 2-Step na Pag-verify',
  FA_tag: 'kakulitan',
  Enabled: 'Pinagana',
  Disabled: 'Hindi pinagana',
  Password: 'Password',
  Modifiedon: 'Binago noong',
  Click_here_to_change_pass_word: 'Mag-click dito upang baguhin ang password',
  current_pass_word: 'Kasalukuyang Password',
  Type_your_current_pass_word: 'I-type ang iyong kasalukuyang password',
  Type_your_new_pass_word: 'I-type ang iyong bagong password',
  Re_type_your_new_pass_word: 'I-type muli ang iyong bagong password',
  new_pass_word: 'Bagong Password',
  confirm_pass_word: 'Kumpirmahin ang Password',
  User_customized_settings: 'Na-customize na mga setting ng user',
  theme: 'Tema',
  dark_theme: 'Madilim na Tema',
  light_thme: 'Banayad na Tema',
  RequestedDate: 'Hiniling na petsa',
  title: 'Pamagat',
  Back_to_Swap: 'Bumalik sa Swap',
  AuditLogs: 'Mga Log ng Audit',
  TimeSpan: 'Oras',
  Features: 'Mga tampok',
  Last_1_Day: 'Huling 1 Araw',
  All_Features: 'Lahat ng Mga Tampok',
  search: 'Maghanap',
  feature: 'Tampok',
  Feature_Path: 'Tampok na Landas',
  Action: 'Aksyon',
  Custom_Dates: 'Mga Custom na Petsa',
  Start_Date: 'Petsa ng Pagsisimula',
  End_Date: 'Petsa ng Pagtatapos',
  Select_Date: 'Piliin ang Petsa',
  ok: 'OK',
  funds_to_swap: 'Hindi sapat na pondo para ipagpalit',
  No_data: 'Walang nakitang data',
  confirm_button: 'Kumpirmahin',
  crypto_with_draw_success: 'Kembali ke Withdraw Crypto',
  Back_to_Withdraw: 'Bumalik sa Withdraw',
  New_Address: 'Bagong tirahan',
  return_to_depositfiat: 'Bumalik sa Deposit Fiat',
  coin: 'barya',
  price: 'Presyo',
  last_24hrs: 'Huling 24 oras',
  home: 'Bahay',
  careers: 'Mga karera',
  legal_policy: 'Legal at Patakaran',
  Deposit_success: 'Tagumpay sa Deposito',
  SelectCurrency: 'Piliin ang Pera',
  select_bank: 'Piliin ang Pangalan ng Bangko',
  BankName: 'Pangalan ng bangko',
  sellCrypto: 'Magbenta ng crypto',
  pleaseSelectWallet: 'Mangyaring pumili ng wallet',
  available_amount_less: 'Ang inilagay na halaga ay dapat na mas mababa kaysa sa magagamit na halaga',
  available_balance_less: 'Ang inilagay na halaga ay dapat na mas mababa sa balanse',
  enter_minvalue: 'Pakilagay ang min value ng',
  enter_coinvalue: 'Mangyaring ipasok mula sa coin Value',
  receive_coin: 'Mangyaring piliin ang tumanggap ng barya upang ipagpalit',
  insufficient_balance: 'Hindi sapat na balanse',
  select_coin: 'Mangyaring pumili mula sa barya',
  coin_swap: 'Mangyaring pumili ng barya na ipapalit',
  is_required: 'Kinakailangan',
  current_pass_word_msg: 'Mangyaring ipasok ang kasalukuyang password',
  new_pass_word_msg: 'Kailangan ng bagong password',
  confirm_pass_word_msg: 'Mangyaring ipasok ang kumpirmahin ang password',
  one_record: 'Mangyaring pumili ng isang tala',
  accept_terms: 'Mangyaring tanggapin ang mga tuntunin ng serbisyo',
  amount_greater_zero: 'Ang halaga ay dapat na mas malaki kaysa sa zero.',
  exceeded_amount: 'Lumampas ka sa maximum na halaga',
  mkt_Cap: 'Mkt Cap',
  total_volume: 'Kabuuang Dami',
  total_supply: 'Kabuuang Supply',
  full_screen: 'Buong screen',
  reload: 'Reload',
  cockpit: 'Sabungan',
  settings_msg: 'Matagumpay na na-save ang mga setting',
  notification_msg: 'Walang Magagamit na Mga Notification',
  bank_msg: 'Hindi available ang mga detalye ng bangko',
  address_msg: 'Matagumpay na na-save ang address',
  add: 'Idagdag',
  edit: 'I-edit',
  active_inactive: 'Aktibo/Hindi Aktibo',
  location: 'lokasyon',
  ipAddress: 'alamat IP',
  menu_wallets: "Mga balanse",
  crypto_wallets: "Dompet Kripto",
  verification_code: "Ipasok ang verification code",
  get_code: "KUMUHA NG OTP",
  resend_code: "RESEND CODE",
  digit_code: "Ilagay ang 6 na digit na code na ipinadala sa",
  selectcurrency: "Pilih Mata Wang",
  bank_account_number: 'Nombor akaun bank',
  invalid_code: 'Kod tidak sah',
  resend_text: 'i-click ang Resend Button pagkatapos ng 2min',
  please_enter_valid_content: 'Sila masukkan kandungan yang sah',
  W: 'W',
  M: 'M',
  Y: 'Y',
  copied: 'Kinopya',
  withdraw_fiat_summary: 'withdraw ang Buod ng Fiat',
  request_fail: 'Naabot na ang pinakamaraming pagsubok, Pakisubukan pagkaraan ng ilang oras',
  received: "Menerima",
  comssion: "Suruhanjaya",
  thousandKText: "Jumlah maksimum ialah $100K.",
  sucessText1: "Pesanan anda telah berjaya dibuat,",
  sucessText2: "telah ditambahkan ke dalam dompet anda.",
  sucessText3: "jumlah telah didebitkan daripada dompet anda.",
  value_should_greater: "Ang halaga ng mula sa barya ay dapat na mas malaki kaysa sa zero",
  asset_directory: 'Direktori Aset',
  you_are_sending: 'Anda menghantar',
  fees: 'Yuran',
  you_receive: 'Anda akan menerima',
  company_name: 'pangalan ng Kumpanya',
  withdrawal_verification: 'withdrawalverification',
  Phone_verification: 'Pag-verify ng Telepono',
  Email_verification: 'Pagpapatunay ng Email',
  addresslabel: 'Label ng Address',
  address_type: 'Address Type',
  addressState: "Alamat Sate",
  add_new_beneficiary: 'Add New Beneficiary',
  kyc_documents: 'KYC Documents',
  confirm_beneficiary: 'Confirm Beneficiary',
  return_to_beneficairy: 'Back to Add New Beneficiary',
  compliance: '(Compliance)',
  upload_documents: 'Upload Documents',
  get_your_own_iban: 'Dapatkan IBAN Anda Sendiri',
  case: 'kasus',
  CaseNumber: 'Nomor kasus',
  CaseTitle: 'Judul Kasus',
  referral: 'Referral',
  referral_id:'Referral Id',
  referral_link:'Referral Link',
  bank_type: "jenis bank",
  iban: "IBAN",
  Bank_Transaction_verification:"Pagpapatunay ng Bank Transfer",
  Live_Verification:"LiveVerification",
}