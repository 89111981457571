import { apiMain } from '../../api/clients';
import { ApiControllers } from '../../api/config';

const favouriteFiatAddress = (member_id, type, coin_code) => {
    return apiMain.get(ApiControllers.depositWithdraw + `Favourite/${member_id}/${type}/${coin_code}`);
}
const detailsAddress = (id) => {
    return apiMain.get(ApiControllers.depositWithdraw + `Withdraw/Favourite/${id}`);
}
const favouriteNameCheck = (member_id, name, type, favaddrId) => {
    return apiMain.get(ApiControllers.depositWithdraw + `Favourite/${favaddrId}/${member_id}/${name}/${type}`);
}
const saveAddress = (obj) => {
    return apiMain.post(ApiControllers.depositWithdraw + `Favourite`, obj);
}
const getCoinList = (type) => {
    return apiMain.get(ApiControllers.markets + `Coins/${type}`);
}
const getSelectedCoinDetails = (coin_code, member_id) => {
    return apiMain.get(ApiControllers.buySell + `${member_id}/Coins/${coin_code}`)
}
const activeInactive = (obj) => {
    return apiMain.put(ApiControllers.master + "useractiveinactive", obj)
}
const getAddress = (addreddId) => {
    return apiMain.get(ApiControllers.depositWithdraw + `Withdraw/Favourite/${addreddId}`)
}
const getFileURL = (obj) => {
    return apiMain.post(ApiControllers.customers + `FetchFile`, obj);
};
export { getCoinList, favouriteFiatAddress, saveAddress, getSelectedCoinDetails, detailsAddress, favouriteNameCheck, activeInactive, getAddress, getFileURL }
