export default {
  header_title: 'Cockpit',
  user_type: '%(lable)s',
  Portfolio_title: 'Crypto Portfolio',
  your_portfolio: 'Your Portfolio',
  suissebase_title: 'Suissebase',
  suissebase_subtitle: 'Fiat Balances',
  markets_title: 'Markets',
  markets_subtitle: 'Trade your crypto into any currency',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  buy: 'Buy',
  sell: 'Sell',
  load_more: 'Load more ...',
  ftr_home: 'Home',
  ftr_careers: 'Careers',
  ftr_tc_p: 'Legan & Policy',
  search_currency: 'Search Currency',
  buy_assets: 'Buy / Sell Assets',
  // past_hours: 'In the past 24 hours',
  deposite_a_crypto: 'Deposit a Crypto',
  deposite_a_cryto_txt: 'Get crypto by simply sharing your QR address',
  purchase_a_crypto: 'Buy your Crypto for Cash',
  // purchase_a_cryto_txt: 'Your wallet is empty, you don’t have any assets to make transactions. Follow this link and Deposit some cash.',
  all: 'Max',
  tabs_All: 'All',
  gainers: 'Gainers',
  losers: 'Losers',
  db_slider_title: 'Get $10 in free crypto',
  db_slider_desc: 'Setup a recurring buy of $50 or more and get $10 after your both 4th and 8th buys',
  db_slider_btn: 'Set up recurring buy',
  selected_crypto: 'Buy %(coin)s',
  crypto_compare_val: '1 %(coin)s - $%(value)s ',
  find_with_wallet: 'Select your wallet',
  refresh_newprice: 'Please refresh to get new price',
  confirm_btn_text: 'Confirm %(counter)s',
  exchange_rate: 'Exchange Rate',
  amount: 'Amount',
  suissebase_fee: 'Suissebase Fee',
  estimated_total: 'Estimated Total',
  summary_hint_text: 'Your final amount might be changed with in 10 seconds.',
  pay: 'Pay %(counter)s',
  cancel: 'Cancel',
  link_a_card: 'Link a Card or Receive',
  select_from_below: 'Select from below',
  credit_card: 'Credit Card',
  credit_card_text: 'Use a credit or debit card',
  desosit_text: 'Deposit from an address or existing wallet',
  name_on_card: 'Name on card',
  card_number: 'Card number',
  expiry: 'Expiry',
  cvv: 'CVV',
  type_billing_address: 'Type in your billing address',
  change_billing_address: 'Change Billing Address',
  confirm_billing_address: 'Confirm Billing Address',
  billing_address: 'Billing Address',
  delivery_address: 'Your delivery address',
  select_a_currency: 'Select a currency',
  address_hint_text: 'Please make sure your delivery address is correct',
  copy: 'Copy',
  share: 'Share',
  from_crypto: 'From Crypto',
  from_fiat: 'From Fiat',
  wire_transfer_mthd: 'Wire transfer method',
  recommended: 'Recommended',
  select_a_method: 'Select a method',
  sepa: 'sepa',
  wire_transfer: 'Wire transfer',
  swift: 'swift',
  no_fee: 'No fee',
  sell_btc: "Sell %(coin)s",
  select_wallet1: "Please select wallet",
  confirm_sell: "Confirm selling your crypto",
  sell_compare_val: '1 %(coin)s - $%(value)s',
  preview: 'Preview %(counter)s',
  success_msg: "Thank You",
  success_decr: "Your order has been placed successfully",
  confirm_msg: 'Waiting for Confirmation',
  confirm_decr: "Your order has been placed Confirm",
  withdraw_a_crypto: "Withdraw a Crypto",
  withdraw_a_crypto_text: 'Withdraw and deposit for your crypto wallet.',
  usd_available: "USD 106.79 available",
  send_wallet_fiat: 'Send from your wallet and into fiat',
  suissebase_personal: 'Suissebase Personal',
  avail_wallet_weprovide: 'Available wallet we provide',
  withdraw_Btc: 'Withdraw BTC',
  withdraw_summary_sub: 'Send your currency address.',
  verify_identity: 'Verify your Identity',
  swap_title: 'New Swap',
  swap_desc: 'Instantly exchange your crypto into any currency we offer in your wallet.',
  swap_from: 'Swap From',
  swap_to: 'To Receive',
  wallet_in_usd: 'Wallet in USD',
  purchase_fiat: 'Purchase a Fiat',
  purchase_fiat_text: 'Your wallet is empty, you don’t have any assets to make transactions. Follow this link and %(link)s  some cash.',
  link_newcard: 'Link new card',
  pick: 'pick',
  confirm_swap: 'Confirm Swap %(counter)s',
  total: 'Total',
  confirm_transaction: 'Deposit Fiat Summary',
  confirm_transaction_decs: 'Wallet in USD',
  add_fund: 'Add Funds',
  confirm_text: 'Swapping 0.00549635 BTC for 0.10183318 ETH',
  deposite_Btc: 'Deposite BTC',
  sell_your_crypto_for_cash: 'Sell your Crypto for Cash',
  sell_your_crypto_for_cash_text: 'Easily buy and sell crypto straight from your wallet.',
  sell_your_fiat_for_cash: 'Sell your Fiat for Cash',
  menu_buy_sell: 'Buy / Sell',
  menu_transactions_history: 'Transactions',
  menu_swap: 'Swap',
  menu_send_receive: 'Deposit / Withdraw',
  menu_mass_pay: 'Mass Payments',
  menu_payments: 'Bill Payments',
  security: 'Security',
  current_balance: 'Current Balance',
  min: 'Min',
  half: 'Half',
  assets: 'Assets',
  address: 'Address',
  confirm_now: 'Confirm Now %(counter)s',
  buy_sell_address: 'UNIT 527 TOWER 4, SMDC Grace Residences, Cayetano Blvd. Brgy. Ususan, Taguig City 1630 PH',
  withdraw_to: 'Withdraw to',
  btc_address: 'BTC Binance Address',
  fund_with_card: 'Fund with card',
  fund_with_card_text: 'How much would you like to add?',
  preview_swap: 'Preview Swap',
  deposit_link: 'Your wallet is empty, you don’t have any assets to make transactions. Follow this link and %(link)s some cash.',
  link: 'link',
  agree_to_suissebase: 'I agree to Suissebase’s %(link)s and its return, refund and cancellation policy.',
  terms_service: 'Terms of Service',
  return_to_buy: 'Back to Buy',
  return_to_sell: 'Back to Sell',
  fidor_bank: 'Fidor Bank AG',
  transaction_submitted: 'Transaction Submitted',
  swapped_btc: 'Swapped 0.00549635 BTC for 0.10183318 ETH',
  bank_frick: 'Bank Frick',
  scan_your_crypto: 'Scan your BTC',
  center_qr: 'Center the QR Scanner',
  close: 'Close',
  note: 'Note',
  card_supported: 'Card type not supported',
  need_to_replenish: 'Need to replenish your wallet? Follow this link and %(link)s some cash.',
  lets_verified: "Let's get you verified",
  confirm_your_identity: 'Suissebase.ch would need to confirm your identity a process is powered by our partner.',
  prepare_valid: 'Prepare a valid document',
  make_sure: "Make sure it's not expired of physically damaged",
  user_smartphone: 'User a smartphone',
  you_need_smartphone: 'You need a smartphone in order to continue',
  qption1: 'Option 1: Scan the QR code',
  qption1_answer: 'Scan the QR code with your camera app',
  qption2: 'Option 2: Send link via SMS',
  qption2_answer: 'We’ll text a secure link to your mobile at no extra cost.',
  return_to_fiat: 'Return to Fiat',
  admin_fee: 'Admin Fee',
  free: 'Free',
  to: 'To',
  from: 'From',
  current_security_level: 'CURRENT SECURITY LEVEL',
  current_security_text: 'Your account has security features switched off, leaving it potentially vulnerable to specific attacks. Set up these security features to improve the security of your account.',
  medium: 'Medium',
  change_pass_word: 'Change Password',
  protect_your_account: 'Protect your account',
  settings: 'Settings',
  general: 'General',
  privacy_policy: 'Privacy Policy',
  logout: 'Logout',
  themes: 'Themes',
  notifications: 'Notifications',
  local_currency: 'Local Currency',
  language: 'Language',
  preferences: 'Preferences',
  wallet_version: 'Wallet Version',
  about: 'About',
  USD_wallet: 'USD Wallet',
  start: 'Start',
  start_text: 'We are a platform that connects banks, payment systems, and people.',
  personal: 'Personal',
  wallets: 'Wallets',
  wallets_text: 'Full control of your private keys.',
  cards: 'Cards',
  exchange: 'Exchange',
  business: 'Business',
  business_text: 'User can create a separate account such as a corporate segregated wallet system.',
  crypto: 'Crypto',
  corporate_wallet: 'Corporate Wallet',
  connect: 'Connect',
  meet_our_team: 'Meet Our Team',
  report_a_bug: 'Report A Bug',
  FAQ: 'FAQ',
  contact_us: 'Contact Us',
  sign_in: 'Sign In',
  security_text: 'Your account has security features switched off, leaving it potentially vulnerable to specific attacks. Set up these security features to improve the security of your account.',
  spend: 'Spend',
  spend_text: 'Your account has security features switched off, leaving it potentially vulnerable to specific attacks. Set up these security features to improve the security of your account.',
  chat: 'Chat',
  current_security: 'CURRENT SECURITY LEVEL',
  status: 'to see your status',
  michael_quiapos: 'Michael Quiapos',
  great: 'Great. i will have a look...',
  address_book: 'Address Book',
  case: 'Cases',
  invite_friends: 'Invite Friends',
  light_theme: 'Light Theme',
  localization: 'Localization',
  localization_text: 'User can create a separate account such as a corporate segregated wallet system.',
  lang: 'lang',
  currency: 'Currency',
  support: 'Support',
  help_center: 'Help Center',
  social_networks: 'Social Networks',
  medium_text: 'CURRENT SECURITY LEVEL %(link)s to see your status',
  backup_wallet: 'Backup Wallet',
  reset_wallet: 'Reset Wallet',
  always_ask_pin: 'Always ask pin',
  activate_face: 'Activate face ID',
  activate_biometry: 'Activate biometry',
  buy_and_sell: 'Buy and Sell',
  swap_services: 'Swap Services',
  deposit_and_withdraw: 'Deposit and Withdraw',
  the_dashboard: 'The Dashboard',
  buy_crypto: 'Buy Crypto',
  Beneficiary_BankDetails: 'Beneficiary Bank Details',
  Bank_account: 'Bank Account Number/IBAN',
  BIC_SWIFT_routing_number: 'BIC/SWIFT/Routing Number',
  Bank_name: 'Bank Name',
  Bank_address: 'Bank Address',
  Bank_address1: 'Bank Address Line 1',
  Bank_address2: 'Bank Address Line 2',
  Bank_address3: 'Bank Address Line 3',
  Beneficiary_Details: 'Beneficiary Details',
  Recipient_full_name: 'Recipient Full Name',
  Recipient_address1: 'Recipient Address Line 1',
  Recipient_address2: 'Recipient Address Line 2',
  Recipient_address3: 'Recipient Address Line 3',
  Reference: 'Reference',
  Fiat_deposit: 'Fiat Deposit / Withdraw ',
  Fiat_withdrawl: 'Fiat Withdrawal',
  Beneficiary_Accountname: 'Beneficiary Account Name',
  Innovative_Concepts: 'INNOVATIVE CONCEPTS AG',
  Beneficiary_Accountaddress: 'Beneficiary Account Address',
  PL_DU_BOURG_DE_FOUR: 'PL DU BOURG DE FOUR6,1204 GENEVE,SWITZERLAND',
  Beneficiary_account: 'Beneficiary Account',
  for_Domestic_wires: 'For Domestic Wires',
  for_international_wires: 'For International Wires',
  Swift_BICcode: 'Swift/BIC Code',
  SIGNU: '%(value)s',
  beneficiaryBank: 'Beneficiary Bank Name',
  signature_bank: '%(value)s',
  beneficiary_Bank_address: 'Beneficiary Bank Address',
  bank_Reference_No: 'Bank Reference No',
  Fifth_Avenue: '%(value)s',
  reference: 'Reference',
  reference_hint_text: 'Important: this code identifies your deposit include this code when submitting the wire transfer in the transaction description or purpose',
  desposite_text: 'Please choose the currency you would like to deposit in, and bank account details for the currency chosen will be displayed.The next step is for you to wire your funds to the bank details provided and remember to include the reference number.',
  EUR_amount: 'EUR Amount',
  Fee: 'Fee',
  Amount_to_pay: 'Amount to pay',
  place_an_order: 'place an order',
  Routing_number: 'Routing Number',
  Choose_a_unique_pass_word_to_protect_your_account: 'Choose a unique password to protect your account.',
  Save_btn_text: 'Save',
  forgot_your_pass_word: 'Forgot your password?',
  Require_all_devices_to_signin: 'Require all devices to sign in ',
  with_new_pass_word: 'with new password',
  Yes: 'Yes',
  summar_info: 'Summary Information',
  current_pass_word: 'Current Password',
  new_pass_word: 'New Password',
  convert: 'Convert',
  EUR_text: 'Please choose the currency you would like to deposit in,and the bank account details for the currency chosen will be displayed.The next step is for you to wire your funds to the bank details provided and remember to INCLUDE the reference number.',
  BICcode: 'BIC',
  EPUALT: 'EPUALT22XXX',
  account_holder_name: 'Account Holder Name',
  innovative_concepts: 'Innovative Concepts',
  wire_16_ZBPHXFHI: 'wire_16_ZBPHXFHI',
  suisse_btn_refresh: "Click to get new rates",
  BankName: 'Bank Name',
  proceed: 'Proceed',
  description: 'Description',
  with_draw: "Withdraw",
  crypto_with_draw_success: "Back to Withdraw Crypto",
  withdrawal_success: 'Withdraw success',
  dep_with_assets: "Crypto Deposit / Withdraw",
  links: "%(link)s",
  remarks: "Remarks",
  Country: "Country",
  state: 'State',
  zipcode: 'Zip code',
  userName: 'User Name',
  manage_account: 'Manage your Account',
  Deposit_success: 'Deposit Success',
  return_to_depositfiat: 'Back to Deposit Fiat',
  withdrawCrypto: 'Withdraw Crypto',
  withdrawFiat: 'Withdraw Fiat',
  cryptoAddress: 'Add Crypto Address',
  fiatAddress: 'Select Fiat Address',
  withdraw_fiat_summary: 'Withdraw Fiat Summary',
  withdraw_live: 'Withdraw Face recognition',
  confirm_withdraw: 'Withdraw confirm',
  back: 'Back',
  liveVarification: 'Withdraw Crypto',
  Withdraw_liveness: 'Live verification',
  confirm: 'Confirm %(counter)s',
  change_10Sec_amount: 'Your amount might be changed within 10 sec',
  tabName_All: 'All',
  searchCurrency: 'Search Currency',
  selectWallet: 'Select Wallet',
  final_Amount: 'Output is estimated only, amount will change.',
  agree_sell: 'I agree to Suissebase’s',
  terms: 'Terms of Service',
  refund_cancellation: ' and its return, refund and cancellation policy.',
  purchase_min: 'You have to purchase for minimum of',
  purchase_max: 'You can only purchase for maximum of',
  enter_wallet: 'Please enter amount to buy',
  select_wallet: 'Please select wallet to proceed',
  insufficientFunds: 'Insufficient funds',
  agree_terms: "Please click the checkbox after reading and agreeing to the Terms of Service before proceeding",
  All: 'All',
  BuyandSell: 'Buy/Sell',
  DepositandFiat: 'Deposit Fiat',
  DepositandCrypto: 'Deposit Crypto',
  accountType: 'Account Type',
  Wallet: 'Wallet',
  RequestDate: 'Request Date',
  walletAddress: 'Wallet Address',
  coinName: 'Coin Name',
  availableCoins: 'Deposited Coins',
  Date: 'Date',
  Type: 'Type',
  FromWallet: 'From Wallet',
  FromValue: 'From Value',
  Status: 'Status',
  ToWallet: 'To Wallet',
  ToValue: 'To Value',
  BeforeValue: 'Before Value',
  AfterValue: 'After Value',
  TotalAmount: 'Total Amount',
  AmountInUsd: 'Amount In Usd',
  ReferenceId: 'Reference Id',
  Select: 'Select',
  space: ' ',
  menu_swap1: 'Swap ',
  Ethereum: "Ethereum",
  balance: 'Balance -',
  available: 'available',
  Available: 'Available',
  ExchangeRate: 'Exchange Rate',
  ProfileInfo: 'Profile Info',
  BasicInfo: "Personal Details",
  SelectAddress: 'Select Address',
  SelectCurrency: 'Select Currency',
  account_details: 'Ensure that the account details are correct',
  Cancel_select: "Transaction can't be cancelled",
  Confirm: 'Confirm',
  totalbalance: 'Total balance',
  select_currency: 'Select a currency in your wallet',
  BasicInfotag: 'Basic Info, like your name and photo, that you use on Suissebase',
  FirstName: 'First Name',
  MiddleName: 'Middle Name',
  LastName: 'Last Name',
  Birthday: 'Birthday',
  ReferenceCode: 'Reference Code',
  ContactInfo: 'Contact Info',
  EmailAddress: 'Email Address',
  PhoneNumber: 'Phone Number',
  address_book_tag: 'User customized address book',
  AddressLabel: 'Address Label',
  Coin: 'Coin',
  submit: 'Submit',
  Enteraddresslabel: 'Address label',
  Enteraddress: 'Enter Address',
  AddFiatAddress: 'ADD FIAT ADDRESS',
  Selectcoin: 'Select Coin',
  documents: 'Documents',
  tab_crypto: 'Crypto',
  tab_fiat: 'Fiat',
  total_balance: 'Total balance',
  wallet_address: 'Select wallet address',
  enter_address: 'Enter address',
  enter_wallet_address: 'Please enter wallet address',
  enter_amount: 'Please enter amount',
  amount_less: 'Entered amount should be less than available balance',
  amount_max: 'Entered amount should be less than max withdraw amount of ',
  amount_min: 'Please enter min value of ',
  tag: 'Tag',
  button: 'Share',
  select_address: 'Select address book',
  searchAddress: 'Search address label',
  oops: 'OOPS',
  address_available: 'No addresses available',
  withdrawSummary: 'Withdraw Crypto Summary',
  selectCurrencyinWallet: 'Select a currency in your wallet',
  agree_termsofservice: 'Please agree terms of service',
  withdraw_crypto: 'Withdraw crypto',
  TwoFactorAuthentication: 'Two Factor Authentication',
  TwoFactorAuthentication_tag: 'Protect your account with 2-step verification',
  FA_tag: '2FA',
  Enabled: 'Enabled',
  Disabled: 'Disabled',
  Password: 'Password',
  Modifiedon: 'Modified on',
  Click_here_to_change_pass_word: 'Click here to change password',
  Type_your_current_pass_word: 'Type your current password',
  Type_your_new_pass_word: 'Type your new password',
  Re_type_your_new_pass_word: 'Re-type your new password',
  confirm_pass_word: 'Confirm Password',
  User_customized_settings: 'User customized settings',
  theme: 'Theme',
  dark_theme: 'Dark Theme',
  light_thme: 'Light Theme',
  RequestedDate: 'Requested Date',
  title: 'Title',
  Back_to_Swap: 'Back to Swap',
  AuditLogs: 'Audit Logs',
  TimeSpan: 'Time Span',
  Features: 'Feature',
  Last_1_Day: 'Last 1 Day',
  All_Features: 'All Features',
  search: 'Search',
  feacture: 'Feacture',
  Feature_Path: 'Feature Path',
  Action: 'Action',
  Custom_Dates: 'Custom Dates',
  Start_Date: 'Start Date',
  End_Date: 'End Date',
  Select_Date: 'Select Date',
  ok: 'OK',
  funds_to_swap: 'Insufficiant funds to swap',
  No_data: 'No data found',
  confirm_button: 'Confirm',
  new_pass_word_msg: 'Please enter new password',
  Back_to_Withdrawfiat: 'Back to Withdraw Fiat',
  New_Address: 'New Address',
  coin: 'Coin',
  price: 'Price',
  beneficiaryAccountName: "Beneficiary Account Name",
  last_24hrs: 'Last 24hrs',
  home: 'Home',
  careers: 'Careers',
  legal_policy: '',
  select_bank: 'Select Bank Name',
  sellCrypto: 'Sell Crypto',
  pleaseSelectWallet: 'Please select wallet',
  available_amount_less: 'Entered amount should be less than available amount',
  available_balance_less: 'Entered amount should be less than balance',
  enter_minvalue: 'You have to sell minimum of ',
  enter_maxvalue: 'You have to sell maximum of ',
  enter_coinvalue: 'Please enter from coin Value',
  receive_coin: 'Please select receive coin to swap',
  insufficient_balance: 'Insufficient balance',
  select_coin: 'Please select from coin',
  coin_swap: 'Please select coin to swap',
  is_required: 'Is required',
  current_pass_word_msg: 'Please enter current password',
  confirm_pass_word_msg: 'Please enter confirm password',
  one_record: 'Please select one record',
  accept_terms: 'Please agree terms of service',
  amount_greater_zero: 'Amount must be greater than zero.',
  exceeded_amount: 'You have exceeded maximum amount',
  mkt_Cap: 'Mkt Cap',
  total_volume: 'Total Volume',
  total_supply: 'Total Supply',
  full_screen: 'Full screen',
  reload: 'Reload',
  cockpit: 'Cockpit',
  settings_msg: 'Settings saved successfully',
  notification_msg: 'No notifications available',
  bank_msg: 'Bank details not available',
  address_msg: 'Address saved successfully',
  add: 'Add',
  edit: 'Edit',
  active_inactive: 'Active/Inactive',
  location: 'Location',
  ipAddress: 'IP Address',
  menu_wallets: "Balances",
  crypto_wallets: "Crypto Balances",
  verification_code: "Enter verification code",
  withdraw_verification_options: "Please select withdraw verification options from below.",
  get_code: "GET CODE",
  resend_code: "RESEND CODE%(counter)s",
  digit_code: " Enter 6 digit code sent to",
  selectcurrency: "Select Currency",
  bank_account_number: 'Bank Account Number',
  invalid_code: 'Invalid code',
  resend_text: 'Click on Resend Button after 2min',
  please_enter_valid_content: 'Please enter valid content',
  W: 'W',
  M: 'M',
  Y: 'Y',
  copied: 'Copied',
  request_fail: "Max attempts reached, Please try after some time",
  received: "Received",
  comssion: "Commission",
  thousandKText: "The maximum amount is $100K.",
  sucessText1: "Your order has been placed successfully,",
  sucessText2: "has been added into your wallet.",
  sucessText3: "amount has been debited from your wallet.",
  value_should_greater: " From coin value must be greater than zero",
  asset_directory: 'Asset Directory',
  you_are_sending: 'You are sending',
  fees: 'Fees',
  you_receive: 'You will receive',
  company_name: 'Business Name',
  withdrawal_verification: 'Withdrawal Verification',
  Phone_verification: 'Phone Verification',
  Email_verification: 'Email Verification',
  get_email: "GET CODE",
  resend_email: "RESEND CODE%(counter)s",
  sent_verification: 'Verification code sent',
  verify_btn: 'VERIFY',
  verify_button: 'VERIFY',
  addresslabel: 'Address Label',
  noaddress_msg: 'Address Book details not available.',
  address_type: 'Address Type',
  addressState: "Address State",
  add_new_beneficiary: 'Add New Beneficiary',
  kyc_documents: 'KYC Documents',
  confirm_beneficiary: 'Confirm Beneficiary',
  return_to_beneficairy: 'Back to Add New Beneficiary',
  compliance: '(Compliance)',
  upload_documents: 'Upload Documents',
  business_recipient: "Business Name/Recipient Full Name",
  get_your_own_iban: 'Get Your Own IBAN',
  phone_invalid_code: 'Invalid phone verification code',
  twofa_invalid_code: 'Invalid authenticator code',
  email_invalid_code: 'Invalid email verification code',
  CaseNumber: 'Case Number',
  CaseTitle: 'Case Title',
  referral: 'Referral',
  referral_id:'Referral Id',
  referral_link:'Referral Link',
	bank_type: "Bank Type",
  iban: "IBAN",
  Bank_Transaction_verification:"Bank Transfer Verification",
  Live_verification:"Live verification",
}